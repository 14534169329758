import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const Kinderwunschpage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Kinderwunsch</title>
        <meta
          name="description"
          content="Die TCM kann bei unerfülltem Kinderwunsch eine sehr gute Unterstützung darstellen."
        />
        <meta property="og:title" content="Kinderwunsch" />
        <meta
          property="og:description"
          content="Die TCM kann bei unerfülltem Kinderwunsch eine sehr gute Unterstützung darstellen."
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Kinderwunsch</h1>
        <SingleImage image={props.data.kinderwunsch.fluid} />
        <p>
          Der Weg zum eigenen Kind kann sich manchmal langwierig und steinig
          zeigen. Unterschiedliche Faktoren haben in den letzten Jahren dazu
          geführt, dass immer mehr Paare ungewollt kinderlos bleiben. Das höhere
          Alter für Erstgebärende, der tägliche Stress, ungesunde Ernährung und
          der Kontakt mit verschiedenen Noxen wie Hormonrückständen, Antibiotika
          oder Weichmacher im Wasser, senken die Qualität der Spermien und
          Eizellen.
        </p>
        <p>
          <strong>Zyklusunregelmässigkeiten</strong> der Frau können
          Schwierigkeiten beim Eisprung, der Befruchtung oder Einnistung
          hervorrufen. Mit einer guten Körper- und Zyklusbeobachtung lassen sich
          Unsicherheiten bzgl. der fruchtbaren Zeit klären. Basaltemperatur
          messen, auf den richtigen Zervixschleim achten geben einen klaren
          Hinweis auf den Eisprung und die fruchtbaren Tage. Eine ausgewogene
          Ernährung und eine gute Balance zwischen Ruhe, Bewegung und Arbeit
          regulieren den Zyklus und machen den Körper der Frau für eine
          Schwangerschaft empfänglicher.
        </p>
        <p>
          Mit Akupunktur und Chinesischen Kräutern können Dysbalancen
          ausgeglichen werden. Der Körper und Geist der Frau können gestärkt und
          beruhigt werden.
        </p>
        <p>
          Auch die sinkende <strong>Spermienqualität</strong> ist ein
          zunehmender Faktor bei Kinderlosigkeit. Erschöpfender Sport, zu viel
          Sauna, ungesunde Ernährung und Stress können die Spermien schädigen.
          Mit chinesischen Kräutern kann die Spermienproduktion reguliert und
          die Qualität wieder verbessert werden.
        </p>
        <p>
          Immer häufiger kommt es zum Einsatz reproduktiver Medizin.
          <strong> Insemination oder IVF/ICSI</strong> bieten oft die letzte
          Möglichkeit zum Wunschkind. TCM begleitet diese Phase angepasst und
          kann Nebenwirkungen der Hormonbehandlung ausgleichen.
        </p>
        <div className="cta">
          <p>Buchen Sie jetzt einen Termin zur Erstanamnese!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default Kinderwunschpage;

export const query = graphql`
  query KiwuImageQuery {
    kinderwunsch: imageSharp(fluid: { originalName: { regex: "/kiwu/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
